let mock = false;
let baseURL;
let isDebuggerProduction = false; // 本地调试线上环境
// let baseURL = '//api.elfinbook.com/';
// let baseURL = '///192.168.50.235:80/api';true
// let baseURL = '//api.book.com/';

// let apiUrl = isDebuggerProduction
//   ? 'https://api.elfinbook.com/'
//   : 'http://api.book.com/';
let platform = 45;

if (process.env.NODE_ENV != 'production') {
  baseURL = '/api';
} else {
  baseURL = '//api.elfinbook.com/';
}

let redirectURL = '//www.elfinbook.com';
let isMockData = process.env.NODE_ENV != 'production' && false; // TODO: 通过ip访问

export {
  mock,
  baseURL,
  redirectURL,
  platform,
  isDebuggerProduction,
  isMockData
};
