let logKey = 'myLog';
import Store from './Store.js';
import { uploadLogFile } from './common.js';
const dayjs = require('dayjs');
const maxSingleLogLength = 10 * 1024;

let totalLogStr = Store.get(logKey) || '';
let prevTag = '';

function log({ type = 'log', args }) {
  try {
    let arr = [...args];
    let strArr = arr.map((v) => {
      if (typeof v == 'object') {
        if (v instanceof Error) {
          let stack = v && v.stack ? v.stack.slice(0, 200) : '';
          return (v.message || '') + '\n' + stack;
        } else {
          return JSON.stringify(v);
        }
      } else {
        return v;
      }
    });
    let logContent = strArr.join(', ').slice(0, 1024);
    try {
      if (typeof logContent === 'object') {
        logContent = JSON.stringify(logContent);
      }
    } catch (error) {}
    let logTime = dayjs(new Date()).format('MM-DD HH:mm:ss');
    let urlPath = location.hash.replace(/t=[^=]/g, ''); // 过滤掉t=xxx无用数据
    let tag = `[${urlPath}]`;
    let logStr = '';
    if (prevTag != tag) {
      logStr = `[${logTime}] [${type}] ${tag}: ${logContent}\n`;
    } else {
      logStr = `[${logTime}] [${type}]: ${logContent}\n`;
    }
    prevTag = tag;
    totalLogStr += logStr;

    Store.set(
      logKey,
      totalLogStr,
      () => {
        uploadLogFile().then((res) => {
          clearLogFile();
        });
      },
      true
    );
  } catch (error) {
    console.error(error);
    // 处理错误
  }
}

function createLogFile() {
  const fileContent = totalLogStr;
  const fileName = `${dayjs(new Date()).format('MM-DD')}.log`;
  const file = new File([fileContent], fileName, { type: 'text/plain' });
  return Promise.resolve(file);
}

function clearLogFile() {
  totalLogStr = '';
  Store.set(logKey, totalLogStr);
}

const logger = {
  log: (...args) => {
    log({ type: 'log', args });
    if (1) {
      console.log(...args);
    }
  },
  info: (...args) => {
    log({ type: 'info', args });
    console.log(...args);
  },
  warn: (...args) => {
    log({ type: 'warn', args });
    console.warn(...args);
  },
  error: (...args) => {
    log({ type: 'error', args });
    console.error(...args);
  }
};

function getLogSize() {
  return Store.set(logKey).length;
}

export { logger, createLogFile, clearLogFile, getLogSize, logKey };
