const processFile = (file) => {
  const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
  return `文件名: ${file.name}, 文件大小: ${sizeInMB} MB`;
};

const processValue = (value, maxLength = 100) => {
  if (typeof value === 'string' && value.length > maxLength) {
    return value.substring(0, maxLength) + '...（已截断）';
  }
  return value;
};

function processRequestData(params, maxLength = 100) {
  if (!params) return params;

  // 处理 File 对象
  if (params instanceof File) {
    return processFile(params);
  }

  // 处理 FormData 对象
  if (params instanceof FormData) {
    const result = {};
    params.forEach((value, key) => {
      result[key] =
        value instanceof File
          ? processFile(value)
          : processValue(value, maxLength);
    });
    return result;
  }

  // 处理普通对象
  if (typeof params === 'object' && !Array.isArray(params)) {
    const result = {};
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        result[key] = processRequestData(params[key], maxLength);
      }
    }
    return result;
  }

  // 处理数组
  if (Array.isArray(params)) {
    return params.map((item) => handleData(item, maxLength));
  }

  // 默认返回原始值
  return params;
}

export default processRequestData;

